import { Card, FlexBox, Typography } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'

export interface Props {
  greeting: string
}

export const Fragment1 = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core'])

  return (
    <FlexBox>
      <Card style={{ width: '100%' }}>
        <Typography textAllCaps fontWeight="bold" mb={1} mr={1}>
          {props.greeting} from Logo 2 Life fragment!
        </Typography>
      </Card>
    </FlexBox>
  )
}
